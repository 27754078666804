<template>
  <section
    class="m-help hidden"
    data-test="find-providers-block"
  >
    <div class="m-help__wrap container relative">
      <h2 class="m-help__title headline-2">
        Выбирайте самостоятельно или обратитесь к нам
      </h2>
      <p class="m-help__subtitle title-5 gray-dark--text">
        Независимо от того, оставили вы заявку на сайте онлайн или решили
        сначала проконсультироваться, мы на связи 24/7. Наши специалисты ответят
        на любые вопросы о подключении и помогут выбрать выгодный тариф
      </p>
      <span class="m-help__color title-5"><img
        loading="lazy"
        src="@/assets/images/useful/operator.svg"
        alt="operator"
      >50
        экспертов контакт-центра готовы вам помочь</span>
      <d-btn
        data-test="find-providers"
        class="m-help__btn title-4"
        color="primary"
        @click="showDialogAddress"
      >
        Найти провайдеров
      </d-btn>
      <img
        loading="lazy"
        :src="
          imgUrl(
            'https://api.dominter.net/media/uploads/blog/mhelp.png',
            951,
            0,
          )
        "
        alt="helper"
        class="m-help__img"
      >
    </div>
  </section>
</template>

<script setup lang="ts">
const ctx = useNuxtApp()
const showDialogAddress = () => {
  ctx.$event('addressDialog', {
    redirect: true,
    label: 'main-margarita',
    title: 'Введите адрес и получите список доступных провайдеров и тарифов',
    goToPage: true,
  })
}

const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}
</script>

<style scoped lang="scss">
.m-help {
  background: linear-gradient(92deg, #e7f1f8 0%, #fff 100%);
  padding-top: 64px;
  padding-bottom: 64px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__color {
    padding-left: 28px;
    position: relative;
    background: linear-gradient(
      89deg,
      #ee3c6b 5.03%,
      #a949e7 42.39%,
      #5f60f5 67.38%,
      #5b73f1 76.39%,
      #5bbbe3 99.87%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    img {
      position: absolute;
      left: 0;
      top: -2px;
      width: 24px;
      height: 24px;
      @media (max-width: getBreakpoint(tablet)) {
        top: -3px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      position: relative;
      z-index: 1;
    }
  }

  &__wrap {
    padding-right: 530px;
    @media (max-width: getBreakpoint(desktop)) {
      padding-right: 300px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-right: 100px;
    }
  }

  &__title {
    margin-bottom: 12px;
    max-width: 450px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
      margin-right: -48px;
      position: relative;
      z-index: 1;
    }
  }
  &__subtitle {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
      position: relative;
      z-index: 1;
    }
  }

  &__btn {
    margin-top: 32px;
    padding: 16px 40px;
    @media (max-width: getBreakpoint(tablet)) {
      position: relative;
      z-index: 1;
      margin-top: 24px;
      padding: 8px 40px;
      width: calc(100vw - 32px);
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }

  &__img {
    position: absolute;
    right: -190px;
    top: -226px;
    width: 634px;
    object-fit: contain;
    object-position: center;
    @media (max-width: getBreakpoint(tablet)) {
      width: 260px;
      top: unset;
      bottom: -25px;
      right: -89px;
    }
  }
}
</style>
